import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RaspagemView from '../views/RaspagemView.vue'
import BlingAuth from '../views/BlingAuth.vue'
import MercadoLivreAuth from '../views/MercadoLivreAuth.vue'
import AmazonAuth from '../views/AmazonAuth.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    //component: HomeView
    component: RaspagemView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/raspagem',
    name: 'raspagem',
    component: RaspagemView
  },
  {
    path: '/bling_auth',
    name: 'bling_auth',
    component: BlingAuth
  },
  {
    path: '/ml_auth',
    name: 'MercadoLivreAuth',
    component: MercadoLivreAuth
  },
  {
    path: '/amazon_auth',
    name: 'AmazonAuth',
    component: AmazonAuth
  },
  {
    path: '/test',
    name: 'test',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
