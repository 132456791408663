<template>
  <br>
  <br>
  <div class="columns">
    <div class="column">
      <div class="columns">
        <div class="column is-10">
          <div class="field is-grouped is-grouped-multiline">&nbsp;&nbsp;
            <div class="control has-text-left" id="isbn">
              <label class="label">Isbn</label>
              <input class="input" :class="{ 'is-danger': !this.livroFormValidate.isbn }" type="text" placeholder="ISBN"
                v-model="this.livro.isbn" v-on:keyup.enter="this.addLivroToList()" v-mask="'#############'">
            </div>
            <div class="control has-text-left" id="qtd">
              <label class="label">Qtd</label>
              <input class="input" :class="{ 'is-danger': !this.livroFormValidate.qtd }" type="text" placeholder="QTD"
                v-model="this.livro.qtd" v-on:keyup.enter="this.addLivroToList()" v-mask="'###'">
            </div>
            <div class="control has-text-left" id="precoVenda">
              <label class="label">Valor</label>
              <input class="input" :class="{ 'is-danger': !this.livroFormValidate.valor }" type="text" placeholder="R$"
                v-model.lazy="this.livro.valor" v-on:keyup.enter="this.addLivroToList()" v-money="money">
            </div>
            <div class="control">
              <label class="label">&nbsp;</label>
              <a class="button is-info has-text-weight-bold" @click="this.addLivroToList()">+</a>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="control">
            <label class="label">&nbsp;</label>
            <div class="select is-info is-rounded">
              <select @change="this.changeBookList($event.target.selectedIndex)">
                <option>Todos os livros</option>
                <option>Prontos para o Bling</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead class="has-background-grey-light">
              <tr>
                <th>ISBN</th>
                <th>QTD</th>
                <th>P.Venda</th>
                <th>Nome</th>
                <th>Autor</th>
                <th>Editora</th>
                <th>Idioma</th>
                <th>Imagem(url)</th>
                <th>P.Capa</th>
                <th>Lar.</th>
                <th>Alt.</th>
                <th>Prof.</th>
                <th>Peso</th>
                <th>Ano</th>
                <th>Pág.</th>
                <th>Descrição</th>
                <th v-if="this.filterList === 1"></th>
                <th v-if="this.filterList === 0"></th>
              </tr>
            </thead>
            <tbody class="is-size-7">
              <tr v-for="item in this.livroList" :key="item.isbn">
                <th><a @click="this.showLivroModal = true, this.isbnModal = item.isbn">{{ item.isbn }}</a></th>
                <td>{{ item.quantidade }}</td>
                <td class="has-text-left">{{ this.converterDecimalParaReais(item.precoVenda) }}</td>
                <td>{{ item.nome }}</td>
                <td>{{ item.autor }}</td>
                <td>{{ item.editora }}</td>
                <td>{{ item.idioma }}</td>
                <td>{{ this.abreviaStringMaior20(item.urlImagem) }}</td>
                <td class="has-text-left">{{ this.converterDecimalParaReais(item.precoCapa) }}</td>
                <td>{{ item.largura }}</td>
                <td>{{ item.altura }}</td>
                <td>{{ item.profundidade }}</td>
                <td>{{ item.peso }}</td>
                <td>{{ item.ano }}</td>
                <td>{{ item.paginas }}</td>
                <td>{{ this.abreviaStringMaior40(item.descricao) }}</td>
                <td v-if="this.filterList === 1"><span class="button bling has-text-weight-bold is-success">!</span></td>
                <td v-if="this.filterList === 0 && this.livroEstaIncompleto(item)"><button
                    class="button is-small is-link btn-raspagem has-text-weight-bold" @click="this.rasparLivro(item)">D</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="columns" v-if="this.filterList === 1 && this.livroList.length > 0">
        <div class="column">
          <div class="field is-grouped is-grouped-multiline">&nbsp;&nbsp;
            <div class="control">
              <a class="button is-success has-text-weight-bold" @click="this.enviarLivrosDisponiveisParaBling()">Enviar
                Lote Bling</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LivroModal v-if="this.showLivroModal" :isbn="this.isbnModal" :showModal="this.showLivroModal"
    @fecharModalLivro="this.showLivroModal = false" @atualizarTabela="this.changeBookList(this.filterList)" />
</template>

<script>
import axios from "axios";
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import LivroModal from '@/components/LivroModal.vue'

export default {
  name: 'RaspagemView',
  directives: { money: VMoney, mask },
  components: {
    LivroModal
  },
  created() {
    this.changeBookList(this.filterList)
  },
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      livroList: [],
      livro: {
        isbn: '',
        qtd: '0',
        valor: 0
      },
      livroFormValidate: {
        isbn: true,
        qtd: true,
        valor: true
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      showLivroModal: false,
      isbnModal: '',
      filterList: 0
    }
  },
  methods: {

    async enviarLivrosDisponiveisParaBling() {
      console.log('entrei');
      await axios.get(this.url + '/livros/enviar_disponiveis_bling')
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.error(err);
        })
      console.log();
    },

    async addLivroToList() {
      if (this.livroFormIsValid()) {
        let path = this.url + '/livros/raspagem'
        var body = {
          isbn: this.livro.isbn,
          quantidade: this.livro.qtd,
          precoVenda: this.converterMoedaParaDecimal(this.livro.valor)
        }
        this.livroList.unshift(JSON.parse(JSON.stringify(body)))


        axios.post(path, body)
          .then(() => {
          })
          .catch(err => {
            console.error(err);
          })

        this.livro.isbn = ''
        this.livro.qtd = '0'
        this.livro.valor = 0
      }
    },

    async buscarListaDeLivrosCadastrados() {
      let path = this.url + '/livros/raspagem/all'
      this.livroList = await axios.get(path)
        .then(res => {
          return res.data

        })
        .catch(err => {
          console.error(err);
        })


    },

    rasparLivro(l) {
      let path = this.url + '/livros/raspagem/' + l.isbn

      axios.post(path)
        .then(() => {
        })
        .catch(err => {
          console.error(err);
        })
    },

    livroFormIsValid() {
      var valid = true
      if (this.isbnIsValid()) {
        this.livroFormValidate.isbn = true
      } else {
        this.livroFormValidate.isbn = false
        valid = false
      }

      if (this.qtdIsValid()) {
        this.livroFormValidate.qtd = true
      } else {
        this.livroFormValidate.qtd = false
        valid = false
      }

      if (this.valorIsValid()) {
        this.livroFormValidate.valor = true
      } else {
        this.livroFormValidate.valor = false
        valid = false
      }

      return valid
    },

    isbnIsValid() {
      const regex = /^\d{13}$/;
      // Verifica se a string não está vazia e corresponde à expressão regular
      if (this.livro.isbn !== '' && regex.test(this.livro.isbn)) {
        return true;
      } else {
        return false;
      }
    },

    qtdIsValid() {
      // Se passou por todas as verificações, retorna true
      return /^(?:\d{1,3}|999)$/.test(this.livro.qtd);
    },

    valorIsValid() {
      let decimal = this.converterMoedaParaDecimal(this.livro.valor)
      if (decimal >= 1) {
        return true
      } else {
        return false
      }
    },

    converterMoedaParaDecimal(valor) {
      // Remove os possíveis separadores de milhares e substitui a vírgula por ponto
      const valorSemPrefixo = valor.replace('R$', '').trim();
      const valorSemSeparadores = valorSemPrefixo.replace(/\./g, '').replace(',', '.');

      // Converte o valor para um número decimal
      const decimal = parseFloat(valorSemSeparadores);

      // Retorna o valor decimal
      return decimal;
    },

    converterDecimalParaReais(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
      return formatter.format(value);
    },

    abreviaStringMaior20(value) {
      if (value && value.length > 20) {
        return value.substring(0, 17) + '...';
      }
      return value;
    },

    abreviaStringMaior40(value) {
      if (value && value.length > 40) {
        return value.substring(0, 37) + '...';
      }
      return value;
    },

    async buscarListadeLivrosProntosBling() {
      let path = this.url + '/livros/raspagem/full'
      await axios.get(path)
        .then(res => {
          this.livroList = res.data

          return res
        })
        .catch(err => {
          console.error(err);
        })
    },

    async changeBookList(e) {
      this.filterList = e
      if (e === 0) {
        await this.buscarListaDeLivrosCadastrados()
      } else if (e === 1) {
        await this.buscarListadeLivrosProntosBling()
      }
    },

    livroEstaIncompleto(l) {
      l.precoCapa = l.precoCapa === '0.00' ? l.precoCapa = '' : l.precoCapa

      for (var key in l) {
        if (l[key] === "" || l[key] === undefined || l[key] === null) {
          return true; // Retorna true se qualquer atributo estiver vazio, nulo ou indefinido
        }
      }
      return false;
    }

  }

}
</script>
<style scoped>
#isbn {
  max-width: 145px;
}

#qtd {
  max-width: 70px;
}

#precoVenda {
  max-width: 110px;
}

.bling {
  max-width: 1px;
  max-height: 6px;
}

.btn-raspagem {
  max-width: 1px;
  max-height: 6px;
}
</style>
