<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background"></div>
    <div class="modal-content has-background-link-light">

      <!-- Any other Bulma elements you want -->
      <div class="section">
        <div class="field is-grouped is-grouped-multiline has-text-left">
          <label class="label">ISBN:&nbsp;</label>
          <label class="label has-text-weight-bold has-text-link-dark">{{ livro.isbn }}</label>
        </div>
        <br>
        <div class="columns ">
          <div class="column is-full">
            <div class="columns">
              <div class="column">
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control has-text-left">
                    <label class="label">Nome</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Nome do Livro"
                      v-model="this.livro.nome" id="nome">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Autor</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Autor"
                      v-model="this.livro.autor">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Editora</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Editora"
                      v-model="this.livro.editora">
                  </div>
                </div>
                <br>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control has-text-left">
                    <label class="label">Preço Venda</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Preço de Venda"
                      v-model="this.livro.precoVenda" v-money="money">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Preço Capa</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Preço de Capa"
                      v-model="this.livro.precoCapa" v-money="money">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Páginas</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="total de páginas"
                      v-model="this.livro.paginas">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Ano</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="ano do livro"
                      v-model="this.livro.ano">
                  </div>
                </div>
                <br>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control has-text-left">
                    <label class="label">Largura</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Largura"
                      v-model="this.livro.largura">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Altura</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Altura"
                      v-model="this.livro.altura">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Profundidade</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Profundidade"
                      v-model="this.livro.profundidade">
                  </div>
                  <div class="control has-text-left">
                    <label class="label">Peso</label>
                    <input class="input is-info has-background-info-light" type="text" placeholder="Peso"
                      v-model="this.livro.peso">
                  </div>
                </div>
                <br>
                <div class="field is-grouped is-grouped-multiline" id="urlImagem">
                  <label class="label">Imagem (url)</label>
                  <input class="input is-info has-background-info-light" type="text" placeholder="endereço url da imagem"
                    v-model="this.livro.urlImagem">
                </div>
                <br>
                <div class="level" id="level">
                  <div class="level-item has-text-centered">
                    <div class="buttons is-centered">
                      <button class="button is-link" @click="this.atualizarLivro()">Salvar</button>
                      <button class="button is-danger" @click="this.$emit('fecharModalLivro')">Cancelar</button>
                    </div>
                  </div>

                  <div class="level-right">
                    <div class="level-item has-text-right">
                      <a class="has-text-danger-dark has-text-weight-bold" @click="this.apagarLivro()">*apagar livro</a>
                    </div>
                  </div>
                </div>








              </div>
              <div class="column is-5">
                <textarea class="textarea is-info has-background-info-light" placeholder="Descrição do Livro"
                  v-model="this.livro.descricao" rows="20"></textarea>
              </div>
            </div>


          </div>
        </div>
      </div>


    </div>
    <button class="modal-close is-large" aria-label="close" @click="this.$emit('fecharModalLivro')"></button>


  </div>
</template>

<script>

import { VMoney } from 'v-money'
import axios from "axios";
export default {
  name: 'LivroModal',
  props: {
    showModal: Boolean,
    isbn: String,
  },
  directives: { money: VMoney },

  data() {
    return {
      livro: {},
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      url: `${process.env.VUE_APP_URL}`,
    }
  },
  async created() {
    //this.livro = 
    await this.buscarLivro()
  },
  methods: {
    async apagarLivro() {
      await axios.delete(this.url + '/livros/' + this.livro.isbn)
        .then(() => {
        })
        .catch(err => {
          console.error(err);
        })

      this.$emit('atualizarTabela')
      this.$emit('fecharModalLivro')
    },

    async buscarLivro() {
      await axios.get(this.url + '/livros/', {
        params: {
          isbn: this.isbn
        }
      })
        .then(res => {
          this.livro = res.data
        })
    },

    async atualizarLivro() {
      var body = JSON.parse(JSON.stringify(this.livro))
      body.precoCapa = this.converterMoedaParaDecimal(body.precoCapa)
      body.precoVenda = this.converterMoedaParaDecimal(body.precoVenda)

      axios.put(this.url + '/livros', body)
        .then(() => {

        })
        .catch(err => {
          console.error(err);
        })

      this.$emit('atualizarTabela')
    },
    converterMoedaParaDecimal(valor) {
      // Remove os possíveis separadores de milhares e substitui a vírgula por ponto
      const valorSemPrefixo = valor.replace('R$', '').trim();
      const valorSemSeparadores = valorSemPrefixo.replace(/\./g, '').replace(',', '.');

      // Converte o valor para um número decimal
      const decimal = parseFloat(valorSemSeparadores);

      // Retorna o valor decimal
      return decimal;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-content {
  min-width: 90%;
  min-height: 65%;
}

#nome {
  min-width: 425px;
}

#urlImagem {
  max-width: 856px;
}

#level {
  max-width: 856px;
}

textarea {
  resize: none;
}
</style>
