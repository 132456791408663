<template>
    <br>
    <br>
    <div class="columns">
        <div class="column"></div>
        <div class="column is-half">

            <div class="field has-text-left">
                <div class="control">
                    <a class="button is-success"
                        href="https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=ab8f15caf232beb5c2632aa497476a18109feff6&state=4785a1363d4b838d0d1fc9672089c7be">Autorizar
                        Aplicativo Bling</a>
                </div>
                <br>
                <br>
                <div class="control">
                    <label class="label">Code</label>
                    <input class="input is-link" type="text" placeholder="Code" v-model="this.code" disabled>
                </div>
                <br>
                <div class="control">
                    <label class="label">State</label>
                    <input class="input is-link" type="text" placeholder="State" v-model="this.state" disabled>
                </div>
                <br>
                <div class="control">
                    <button class="button is-link" @click="this.gerarToken()">Salvar</button>
                </div>
            </div>
        </div>
        <div class="column"></div>
    </div>
</template>
  
<script>
// @ is an alias to /src
import axios from "axios";
export default {
    name: 'BlingAuth',
    components: {

    },
    data() {
        return {
            code: this.$route.query.code,
            state: this.state = this.$route.query.state,
            url: `${process.env.VUE_APP_URL}`,
        }
    },
    methods: {
        async gerarToken() {
            if ((this.code !== '' && this.code !== undefined)) {
                let endpoint = '/livros/bling_token'
                let body = {
                    code: this.code,
                    state: this.state
                }

                await axios.post(this.url + endpoint, body)
                    .then(res => {
                        console.log(res)
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        }
    },
}
</script>
  