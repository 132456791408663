<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-half-desktop is-two-thirds-tablet">
        <form @submit.prevent="submitForm">
          <div class="field">
            <label class="label has-text-left">Nome</label>
            <div class="control">
              <input class="input" type="text" v-model="formData.nome" placeholder="Seu nome">
              <p v-if="!isNameValid" class="help is-danger has-text-left">Por favor, insira seu nome.</p>
            </div>
          </div>

          <div class="field">
            <label class="label has-text-left">Sobrenome</label>
            <div class="control">
              <input class="input" type="text" v-model="formData.sobrenome" placeholder="Seu sobrenome">
              <p v-if="!isLastNameValid" class="help is-danger has-text-left">Por favor, insira seu sobrenome.</p>
            </div>
          </div>

          <div class="field">
            <label class="label has-text-left">Email</label>
            <div class="control">
              <input class="input" type="email" v-model="formData.email" placeholder="Seu email">
              <p v-if="!isEmailValid" class="help is-danger has-text-left">Por favor, insira um email válido.</p>
            </div>
          </div>

          <div class="field">
            <label class="label has-text-left">Senha</label>
            <div class="control">
              <input class="input" type="password" v-model="formData.senha" placeholder="Sua senha">
              <p v-if="!isPasswordValid" class="help is-danger has-text-left">A senha deve ter pelo menos 6 caracteres.</p>
            </div>
          </div>

          <div class="field">
            <label class="label has-text-left">Repita a Senha</label>
            <div class="control">
              <input class="input" type="password" v-model="confirmPassword" placeholder="Repita sua senha">
              <p v-if="!isPasswordMatch" class="help is-danger has-text-left">As senhas não coincidem.</p>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button class="button is-primary">Enviar</button>
            </div>
            <div class="control">
              <button class="button is-light">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        nome: '',
        sobrenome: '',
        email: '',
        senha: ''
      },
      confirmPassword: '',
      isNameValid: true,
      isLastNameValid: true,
      isEmailValid: true,
      isPasswordValid: true,
      isPasswordMatch: true
    };
  },
  methods: {
    submitForm() {
      this.validateForm();
      if (this.isFormValid) {
        // Aqui você pode adicionar a lógica para enviar os dados do formulário para o servidor
        console.log('Dados do formulário:', this.formData);
      }
    },
    validateForm() {
      this.isNameValid = !!this.formData.nome;
      this.isLastNameValid = !!this.formData.sobrenome;
      this.isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email);
      this.isPasswordValid = this.formData.senha.length >= 6;
      this.isPasswordMatch = this.formData.senha === this.confirmPassword;
    }
  },
  computed: {
    isFormValid() {
      return (
        this.isNameValid &&
        this.isLastNameValid &&
        this.isEmailValid &&
        this.isPasswordValid &&
        this.isPasswordMatch
      );
    }
  }
};
</script>
