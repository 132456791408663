<template>
    <br>
    <br>
    <div class="columns">
        <div class="column"></div>
        <div class="column is-half">

            <div class="field has-text-left">
                <div class="control">
                    <a class="button is-success" :href="this.montarUrlAuth()">Autorizar
                        Aplicativo Amazon</a>
                </div>
                <br>
                <br>
                <div class="control">
                    <label class="label">Code</label>
                    <input class="input is-link" type="text" placeholder="Code" v-model="this.code" disabled>
                </div>
                <br>
                <div class="control">
                    <label class="label">State</label>
                    <input class="input is-link" type="text" placeholder="State" v-model="this.state" disabled>
                </div>
                <br>
                <div class="control">
                    <button class="button is-link" @click="this.gerarToken()">Salvar</button>
                </div>
            </div>
        </div>
        <div class="column"></div>
    </div>
</template>
  
<script>
import axios from "axios";
// @ is an alias to /src
export default {
    name: 'AmazonAuth',
    components: {

    },
    created() {
        this.code = this.$route.query.code
        this.state = this.$route.query.state

    },
    data() {
        return {
            app_id: process.env.VUE_APP_ID,
            state_random: this.gerarCodigoRandom(),
            redirect_uri: 'https://sellercentral.amazon.com.br/apps/authorize/consent?application_id=amzn1.sp.solution.4f1bf493-ea38-43d1-8c22-001f92969379',
            code: '',
            url: `${process.env.VUE_APP_URL}`,
        }
    },
    methods: {

        montarUrlAuth() {
            return `https://sellercentral.amazon.com.br/apps/authorize/consent?application_id=amzn1.sp.solution.4f1bf493-ea38-43d1-8c22-001f92969379`
        },
        gerarCodigoRandom() {
            var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var codigo = '';

            // Gera um código com pelo menos 10 caracteres
            while (codigo.length < 10) {
                var indiceAleatorio = Math.floor(Math.random() * caracteres.length);
                codigo += caracteres.charAt(indiceAleatorio);
            }

            return codigo;
        },
        gerarToken() {
            let body = {
                code: this.code,
                redirect_uri: this.redirect_uri
            }

            axios.post(this.url + `/livros/catavento/ml_token`, body)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        }

    },
}
</script>
  